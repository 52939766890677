<template>
  <n-modal
    v-model:show="showCouponModal"
    preset="card"
    title="我的优惠券"
    :style="{ width: '1000px' }"
    @close="handleClose"
    :on-mask-click="handleClose"
  >
    <n-tabs
      type="line"
      default-value="unused"
      @update:value="handleUpdateValue"
    >
      <n-tab-pane name="unused" tab="待使用">
        <n-data-table
          :columns="unusedColumns"
          :data="unusedCoupons"
          :row-key="(row) => row.id"
          :loading="tableLoading"
        />
      </n-tab-pane>
      <n-tab-pane name="expired" tab="已失效">
        <n-data-table
          :columns="expiredColumns"
          :data="expiredCoupons"
          :row-key="(row) => row.id"
          :loading="tableLoading"
        />
      </n-tab-pane>
    </n-tabs>
    <n-modal
      v-model:show="invalidationModal"
      preset="card"
      title="失效原因"
      :style="{ width: '400px' }"
    >
      <h4>优惠券名称: {{ cardName }}</h4>
      <h4>失效原因: {{ causeOfFailure }}</h4>
    </n-modal>
  </n-modal>
</template>

<script setup>
import { ref, computed, h, watch, onMounted } from "vue";
import { NModal, NTabs, NTabPane, NDataTable, NButton } from "naive-ui";
import { getUserCouponsApi, getUserCouponDetaildApi } from "@/api/pay";
import { useUserStore } from "@/store";
import { Message } from "@/utils/tips.ts";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:show", "toUse:value"]);

const showCouponModal = ref(false);
const userStore = useUserStore();
const unusedCoupons = ref();
const expiredCoupons = ref();
const tableLoading = ref();
const cardName = ref("");
const causeOfFailure = ref("");
const invalidationModal = ref(false);

const fetchCoupons = async (isUsed) => {
  tableLoading.value = true;
  try {
    const response = await getUserCouponsApi(userStore.javaUser.userId, isUsed);
    if (isUsed) {
      if (response.code !== 200 || response.data === null) {
        tableLoading.value = false;
        expiredCoupons.value = [];
        return;
      }
      expiredCoupons.value = response.data.data;
    } else {
      if (response.code !== 200 || response.data === null) {
        tableLoading.value = false;
        unusedCoupons.value = [];
        return;
      }
      unusedCoupons.value = response.data.data;
    }
    tableLoading.value = false;
  } catch (error) {
    console.error("Failed to fetch coupons:", error);
    Message.error(error.message);
    tableLoading.value = false;
  }
};

const handleClose = () => {
  emit("update:show", false);
  showCouponModal.value = false;
};

// onMounted(() => {
//   fetchCoupons(false);
// });

const handleUpdateValue = (value) => {
  console.log(value);
  const flag = value === "expired";
  fetchCoupons(flag);
};

const unusedColumns = [
  { title: "名称", key: "coupon.couponName" },
  {
    title: "领取时间",
    key: "issueDate",
    render: (row) => new Date(row.issueDate).toLocaleDateString(),
  },
  { title: "状态", key: "coupon.statusName" },
  {
    title: "操作",
    key: "actions",
    render: (row) =>
      h(
        NButton,
        {
          size: "small",
          onClick: () => handleUseCoupon(row),
        },
        { default: () => "去使用" }
      ),
  },
];

const expiredColumns = [
  { title: "名称", key: "coupon.couponName" },
  {
    title: "领取时间",
    key: "issueDate",
    render: (row) => new Date(row.issueDate).toLocaleDateString(),
  },
  { title: "状态", key: "coupon.statusName" },
  // {
  //   title: "操作",
  //   key: "actions",
  //   render: (row) =>
  //     h(
  //       NButton,
  //       {
  //         size: "small",
  //         onClick: () => handleViewCoupon(row),
  //       },
  //       { default: () => "查看" }
  //     ),
  // },
];

const handleUseCoupon = (row) => {
  console.log("去使用", row);
  emit("toUse:value");
  emit("update:show", false);
  showCouponModal.value = false;
};

const handleViewCoupon = async (row) => {
  try {
    const res = await getUserCouponDetaildApi({
      userId: userStore.javaUser.userId,
      id: row.id,
    });
    cardName.value = res.data.data.name;
    causeOfFailure.value = res.data.data.use;
    invalidationModal.value = true;
  } catch (err) {
    console.log(err);
  }
};

watch(
  () => props.show,
  (newVal) => {
    showCouponModal.value = newVal;
  }
);
</script>

<style scoped>
/* 你可以在这里添加一些样式 */
</style>
